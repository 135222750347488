/*
Platform-switcher
=================
*/

/* # Show default variant for users without javascript */
html.no-js {
	.js-platform-switch {
		.js-pc {
			position: relative;
			left: auto;
		}
		.js-android,
		.js-android-market,
		.js-ios,
		.js-mac {
			position: absolute;
			left: -9999px;
		}
	}
}

/* # PC */
html.js-pc {
	.js-platform-others {
		.js-pc {
			display: none;
		}
	}

	.js-platform-switch {
		.js-android,
		.js-android-market,
		.js-ios,
		.js-mac {
			display: none;
		}
	}
}

/* # Android*/
html.js-android {
	.js-platform-others {
		.js-android {
			display: none;
		}
	}

	.js-platform-switch {
		.js-pc,
		.js-android-market,
		.js-ios,
		.js-mac {
			display: none;
		}
	}
}

/* # iOS */
html.js-ios {
	.js-platform-others {
		.js-ios {
			display: none;
		}
	}

	.js-platform-switch {
		.js-android,
		.js-android-market,
		.js-pc,
		.js-mac {
			display: none;
		}
	}
}

/* # Mac */
html.js-mac {
	.js-platform-others {
		.js-mac {
			display: none;
		}
	}

	.js-platform-switch {
		.js-android,
		.js-android-market,
		.js-pc,
		.js-ios {
			display: none;
		}
	}
}
