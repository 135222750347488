@import '../../variables/variables.less';

.form-select {
	appearance: none;
	padding: 11px 57px 9px 30px;
	background-color: @Grey-4;
	color: @Black;
	font-size: 14px;
	line-height: normal;
	font-weight: 400;
	border-radius: 3px;
	position: relative;
	max-width: 300px;
	border: 1px solid @Grey-3;
    cursor: pointer;
    
    // added ellipsis
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.form-select:disabled {
	cursor: initial;
	padding: 11px 30px 9px 30px;
}

.form-select-arrow {
	position: relative;
	display: inline-block;

	&:after {
		content: url('../i/ico/icon-arrow-down.svg');
		display: block;
		position: absolute;
		top: 8px;
		right: 15px;
		pointer-events: none;
	}
	&.disabled:after {
		content: '';
	}

	// IE11
	select::-ms-expand{
		display: none;
	}
}
