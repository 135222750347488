@import 'style-guide/components/platform-switcher.less';
@import 'style-guide/components/help-me-choose-tool.less';

.buttons {
	.ios {
		a:not(.button) {
			color: #ffffff;
			text-decoration: underline;
		}
		.button-app-store {
			&.white {
				width: 192px;
			}
		}
	}
}

html.js-ios {
	.banner-0 {
		background-size: cover;
	}

	.other-platform {
		display: none;
	}
}

.challenger {display: none;}

.challenger-link {
	display: inline-block;
	padding-top: 80px;
}

html.js-android {
	.android-platform {
		display: block;
	}
	.other-platform {
		display: none;
	}
}

html.js-mac,
html.js-pc {
	.android-platform {
		display: none;
	}

	.other-platform {
		display: block;
	}
}

.banner-0 {
	background-size: cover;
	padding-bottom: 170px;

	.js-platform-switch {
		padding-bottom: 20px;
	}
}

.js-pc .banner-0 .js-platform-switch, .js-mac .banner-0 .js-platform-switch {
	padding-bottom: 0px!important;
}
.js-pc .banner-0, .js-mac .banner-0 {
	padding-top: 172px!important;
}

.js-pc .banner-0 .buttons,.js-mac .banner-0 .buttons {
	margin-bottom: 25px;
}


/* logo fix */
.col-sni-10-ref-43 {
	padding-top:10px;
}


/* windows 7 banner */

// banner style
.banner-win7 {

	// hidden by default
	display: none;

	.banner-content {
		background-color: #45cc89;

		@media only screen and (min-width: 768px) {
			padding: 20px 80px;
			min-height: 110px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		@media only screen and (max-width: 767px) {
			padding: 5px 40px 30px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.banner-text {
				text-align: center;
			}
		}

		.banner-img {
			display: inline-block;
			margin-right: 10px;

			img {
				vertical-align: middle;

				@media only screen and (min-width: 768px) {
					margin-right: 10px;
				}

				@media only screen and (max-width: 767px) {
					margin-bottom: 10px;
				}

			}

		}

		.banner-text {
			position: relative;
			top: 3px;
			color: #ffffff;
			font-family: 'AVGSans_Light',Verdana,sans-serif;
			letter-spacing: -0.03em;
			font-weight: 500;

			@media only screen and (min-width: 768px) {
				font-size: 20px;
			}
			@media only screen and (max-width: 767px) {
				font-size: 18px;
			}

			.font-emphased {
				font-family: 'AVGSans_4',Verdana,sans-serif;
			}

			a {
				color: #fff;
				border-bottom: 1px solid #ffffff;

				&:hover {
					text-decoration: none;
				}
			}
		}

	}
}

/* Show banner for Win7 */
body[data-os="Windows"][data-os-version="7"] {

	.banner-devices.default {
		display: none;
	}
	.banner-win7.noAV {
		display: block;
	}
	.banner-win7.withAV {
		display: none;
	}

}

/* Show banner for Win7 with program installed */
body[data-os="Windows"][data-os-version="7"][data-av-program="true"] {

	.banner-devices.default {
		display: none;
	}
	.banner-win7.noAV {
		display: none;
	}
	.banner-win7.withAV {
		display: block;
	}

}

.space__text{
	padding-top: 20px;
}

.labelOff {
        background-image: url(../i/other/icon-box_20-percent-off-business-badge_90x90.png);
        background-repeat: no-repeat;
        position: absolute;
        height: 90px;
        width: 90px;
        left: 0;
        top: 0;
	  }
.content-box-109-inner {
		background-repeat: no-repeat;
		background-position: center center;
	  }

	  .banner-24 {
		background-image: url(../i/uis/illustration-business_computer-with-documents_690x340-updated.jpg);

		background-repeat: no-repeat;
		background-position: center bottom;
	}

	@media (max-width: 767px) {
		.banner-24 {
			background-image: none;
		}
	}

	@media (max-width: 479px) {
		.banner-24 {
			background-repeat: no-repeat;
			background-position: center bottom;
			background-image: none;
		}
	}




//
//	GLOWEB-1935
//
.gloweb-1935--banner {
	padding: 80px 32px;
	background: #F5F6FA;

	.icon {
		width: 80px;
		height: 80px;
		margin-bottom: 40px;

		@media (max-width: 767px) {
			margin-bottom: 20px;
		}
	}

	.h1	{
		line-height: 44px;
		margin-bottom: 16px;
	}

	p {
		margin-bottom: 64px;
		color: #5C707B;

		@media (max-width: 767px) {
			margin-bottom: 32px;
		}
	}
}
