@import '../../variables/variables.less';
@import './form-select.less';

@media @desktop, @tablet {
	.row {
		&.actionbox {
			display: flex;
			flex-direction: row;
			align-items: stretch;
			margin-bottom: 20px;
		}
	}
}

.vue-action-box {
	height: 100%;

	.actionbox-container {
		position: relative;
		background: @White;
		border-radius: 3px;
		padding: 44px 20px 32px;
		width: 100%;
		height: 100%;
		
		&:not(.boxCount-2, .boxCount-3) {
			max-width: 316px;
		}
		@media @mobile {
			min-width: 335px;
		}
	}

	// Toggler
	.form-select-container {
		display: inline-block;
		top: -19px;
		position: absolute;
		right: 0;
		left: 0;
		margin: 0 auto;
		text-align: center;
		min-height: 70px;

		@media @mobile {
			position: relative;
			text-align: center;
		}

		.form-select-arrow {
			.form-select {
				background-color: @White;
				border: solid 1px @Grey-4;
				padding-bottom: 11px;
			}

			.form-select:disabled {
				cursor: initial;
				background-color: @Grey-4;
				color: @Grey-1;
				margin: 0;
				opacity: 1;
			}

			&:after {
				content: url('../i/ico/icon-arrow-down-blue.svg');
			}
		}

		.disabled {
			&:after {
				content: '';
			}
		}
	}
	// /Toggler

	.box {
		text-align: center;
		border-right: 1px solid @Grey-4;
		flex: 1;

		a {
			color: @Black;
			text-decoration: underline;

			&.actionbox-button,
			&.try-button {
				min-width: 139px;
				padding: 11px 36px;
				border-radius: 4px;
				background-image: linear-gradient(to bottom, #57c131, #399f2d);
				display: inline-block;
				color: @White;
				text-decoration: none;
				margin: 20px 0 10px 0;
				font-family: AVGSans_4, Verdana;

				&:hover,
				&:focus,
				&:active {
					background-image: none;
					background-color: #399e2d;
				}
			}

			.trial-button {
				display: inline-block;
				min-width: 139px;
				padding: 11px 20px 11px 50px;
				border-radius: 4px;
				text-decoration: none;
				color: @blueButtonBgColor;
				background-color: @primaryButtonColor;
				border: 1px solid @blueButtonBgColor;
				background-position: 9% 50%;
				background-size: 24px 24px;
				background-repeat: no-repeat;
				font-family: AVGSans_4, Verdana;
				&:hover {
					background-color: @blueButtonBgColorHover;
					color: @primaryButtonColor;
				}
				&.win {
					background-image: url('../i/buttons/win-blue.svg');
					&:hover {
						background-image: url('../i/buttons/win-white.svg') !important; // overwrite .button styles
					}
				}
				&.mac {
					background-image: url('../i/buttons/mac-blue.svg');
					margin-top: -17px;
					&:hover {
						background-image: url('../i/buttons/mac-white.svg') !important; // overwrite .button styles
					}
				}
			}
		}

		p {
			color: @Grey-4;
			font-size: 14px;
			padding: 0 20px;
		}

		//installment used to come from pricing portal with <b> tag
		.installments-wrapper {
			.installments-value {
				font-weight: 600;
				color: @Black;
			}
		}

		&:last-of-type {
			border-right: none;
		}

		@media @mobile {
			width: 100%;
			border-right: none;
			display: none;

			&:first-of-type {
				display: block;
			}
		}
	}

	.actionbox-buy {
		height: 100%;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: stretch;

		@media @mobile {
			display: block;
			width: 100%;
		}
	}

	.actionbox-title {
		color: @Grey-1;
		font-size: 16px;
		line-height: 28px;
		font-weight: 300;
		display: block;
		margin-bottom: 25px;
	}

	.hide-desktop-title {
		@media @desktop {
			display: none;
		}
	}

	.hide-mobile-title {
		@media @mobile {
			display: none;
		}
	}

	.hide-desktop-period {
		@media @desktop {
			display: none;
		}
	}

	.platforms {
		align-items: center;
		display: flex;
		justify-content: space-around;
		margin: 0 auto;
		margin-bottom: 15px;
		margin-top: -20px;
		max-width: 125px;
		min-height: 30px;
	}

	.period-wrap {
		.price-wrapper {
			color: @Black;
			display: inline-flex;
			align-items: stretch;
			position: relative;
			margin-bottom: -25px;
			justify-content: center;
	
			&.price-row-medium {
				font-size: 36px;
			}
	
			.integer {
				font-size: 34px;
				line-height: 34px;
				letter-spacing: -0.044em;
			}
	
			.price-prefix {
				display: flex;
				margin-right: 2px;
				align-items: flex-end;
	
				
			}
	
			.price-suffix {
				text-align: left;
				display: flex;
				flex-direction: column;
			}
	
			.price-suffix-bottom {
				display: flex;
				margin-top: auto;
			}
	
		}
		.decimal {
			font-size: 14px;
			line-height: 17px;
			margin-left: 3px;
			color: @Grey-2;
		}


		.period {
			align-self: flex-end;
			position: relative;
			color: @Grey-1;
			text-align: left;
			margin-left: 5px;
			font-size: 14px;
			line-height: 17px;
		}

		.currency {
			font-size: 14px;
			line-height: 17px;
		}
	}

	.label {
		background-color: #d4e5f9;
		border-radius: 3px;
		display: inline-block;
		margin: 0 auto 16px;
		padding: 2px 9px;
		text-align: center;
		text-overflow: ellipsis;
		vertical-align: middle;
		// white-space: nowrap;
	}

	.label-table-mobile {
		display: none;
	}

	.label-text {
		font-size: 14px;
		text-transform: uppercase;
		line-height: normal;
		color: @Blue;
	}

	.label-placeholder {
		height: 24px;
		margin-bottom: 16px;
	}

	.rendered-price {
		display: inline;
	}

	.actionbox-price-main {
		display: flex;
		flex-direction: column;
	}

	.period-wrap {
		display: inline-flex;
		align-items: flex-end;
		justify-content: center;
		position: relative;
	}

	.old-price {
		display: inline-block;
		font-size: 16px;
		color: @Grey-2;
		text-decoration: line-through;
	}

	.month-price {
		display: inline-block;
		font-size: 12px;
		line-height: 18px;
		color: #5C707B;

		.price-wrapper {
			font-size: 13px;
			line-height: 18px;
		}
	}

	.discount-placeholder {
		height: 24px;
	}

	.header-footer-wrap {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.header-wrap {
		flex: 1 0 auto;
	}

	.labelActive {
		flex: 2 0 auto;
	}

	.footer-wrap {
		flex: 2 0 auto;

		.cta-note {
			.renewal-trigger {
				cursor: pointer;
				font-size: 14px;
				width: 90%;
				color: #5C707B;
				display: block;
				margin: 0px auto;
			}
		}
	}

	.button {
		margin-bottom: 8px;
		margin-top: 20px;

		span {
			min-width: 139px;
		}
	}



	.bottom-text {
		font-size: 12px;
		line-height: 18px;
		color: #767683;
		font-weight: normal;
		//max-width: 65%;
		margin: 0 auto;
		padding: 0 10px;
	}

	.store-link {
		display: block;
		margin-top: 25px;
	}

	.horizontal-toggler {
		display: flex;
		justify-content: center;
		top: -19px;
		position: absolute;
		right: 0;
		left: 0;
		margin: 0 auto;

		@media @mobile {
			margin: 0 20px;
		}

		.horizontal-toggler-container {
			display: flex;
			background-color: @White;
			border-radius: 3px;
			border: 1px solid #bbc1cc;

			@media @mobile {
				max-width: 100%;
			}

			.toggler-option {
				display: flex;
				align-items: center;
				justify-content: center;
				color: #000;
				cursor: pointer;
				line-height: 32px;
				min-width: 80px;
				margin: 3px;
				padding-left: 20px;
				padding-right: 20px;
				font-size: 14px;
				text-align: center;
				white-space: nowrap;

				&.disabled {
					cursor: initial;
					background-color: @Grey-4;
					color: @Grey-1;
					line-height: 40px;
					margin: 0;
				}

				&:last-child:not(:only-child) {
					border-right: none;
				}

				&.active {
					background-color: @Grey-5;
					color: #000;
					font-weight: bold;
					letter-spacing: -0.3px;
				}
			}
		}
	}
}

.actionbox-btm {
	.vue-action-box {
		box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
	}
}


// Store actionbox buttons
.store {
	.vue-action-box {
		.box {
			a {
				&.actionbox-button,
				&.try-button {
					font-size: 18px;
					min-width: 139px;
					background-color: @Blue;
					background-image: none;
					display: inline-block;
					color: @White;
					text-decoration: none;
					margin: 20px 0 10px 0;
					font-family: AVGSans_4, Verdana;
					padding: 0;

					span {
						display: inline-flex;
						justify-content: space-between;
						align-items: center;
						vertical-align: middle;
						padding: 6px 32px;
						border-radius: 6px;
						height: 56px;
						line-height: 0.85;
						font-weight: bold;
					}

					&:hover,
					&:focus,
					&:active {
						background-image: none;
						background-color: @Blue;
					}
				}
			}
		}
	}
}

/* Facelift */
.actionbox-facelift {
	.span8 {
		@media @tablet {
			flex-basis: 76%;
			padding-left: 0;
		}
	}

	.vue-action-box {
		.actionbox-container {
			border-radius: 0;
		}

		.toggler-option {
			&.disabled {
				//border: solid 1px rgba(199, 205, 215, 0.6);
				background-color: @White;
				color: @Grey-1;
				border-radius: 0;
				line-height: 38px;
			}
		}

		.form-select-arrow {
			.form-select {
				border-radius: 0;
				background-color: @White;
				border: solid 1px @Grey-4;
				padding-bottom: 11px;
			}

			&:after {
				content: url(../i/ico/icon-arrow-down-blue.svg);
			}
		}

		// Toggler
		.form-select-container {
			display: inline-block;
			top: -19px;
			position: absolute;
			right: 0;
			left: 0;
			margin: 0 auto;
			text-align: center;
			min-height: 70px;

			@media @mobile {
				position: relative;
			}
		}
		// /Toggler

		.box {
			text-align: center;
			border-right: 1px solid @Grey-4;
			flex: 1;

			@media @mobile {
				border-right: none;
			}

			a {
				color: @Blue;
				padding-bottom: 2px;
				border-bottom: 1px dotted @Blue;

				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
				}
			}

			&:last-of-type {
				border-right: none;
			}
		}

		p {
			color: @Grey-1;
			font-size: 14px;
			padding: 0;
			margin: 0;
		}

		@media @mobile, @tablet {
			width: 100%;
			border-right: none;
			display: none;

			&:first-of-type {
				display: block;
			}
		}

		.actionbox-buy {
			height: 100%;
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: stretch;

			@media @mobile {
				padding-top: 10px;
				display: block;
				width: 100%;
			}
		}

		.actionbox-title {
			color: @Grey-0;
			font-size: 24px;
			line-height: 28px;
			font-weight: bold;
			display: block;
			margin-bottom: 16px;
		}

		.promotion {
			.price-wrapper {
				color: @Blue;
			}
		}

		.platforms {
			align-items: center;
			display: flex;
			justify-content: space-around;
			margin: 0 auto;
			margin-bottom: 15px;
			max-width: 125px;
			min-height: 30px;
		}

		.label {
			font-size: 14px !important;
			background-color: #d4e5f9;
			border-radius: 11px;
			display: inline-block;
			margin-bottom: 16px;
			margin-top: 0;
			padding: 2px 9px;
			text-align: center;
			text-overflow: ellipsis;
			vertical-align: middle;
			// white-space: nowrap;
		}

		.label-table-mobile {
			display: none;
		}

		.label-text {
			font-size: 14px;
			text-transform: uppercase;
			line-height: normal;
			color: @Blue;
			font-weight: bold;
		}

		.label-placeholder {
			height: 24px;
			margin-bottom: 16px;
		}

		.discount-placeholder {
			height: 24px;
		}

		a {
			&.actionbox-button {
				font-size: 18px;
				min-width: 139px;
				background-color: @Blue;
				background-image: none;
				display: inline-block;
				color: @White;
				text-decoration: none;
				margin-bottom: 12px;
				margin-top: 20px;
				font-family: AVGSans_4, Verdana;
				padding: 0;

				span {
					display: inline-flex;
					justify-content: space-between;
					align-items: center;
					vertical-align: middle;
					padding: 6px 32px;
					border-radius: 6px;
					height: 56px;
					line-height: 0.85;
					font-weight: bold;
				}

				&:hover,
				&:focus,
				&:active {
					background-image: none;
					background-color: #4796f5;
				}
			}
		}

		.bottom-text {
			color: @Grey-1;
			margin: 0 auto;

			p {
				margin-bottom: 5px;
			}
		}

		.actionbox-btm {
			.vue-action-box {
				box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
			}
		}

		.horizontal-toggler {
			display: flex;
			justify-content: center;
			top: -19px;
			position: absolute;
			right: 0;
			left: 0;
			margin: 0 auto;

			@media @mobile {
				margin: 0 20px;
			}

			.horizontal-toggler-container {
				display: flex;
				border-radius: 0;
				border: 1px solid rgba(199, 205, 215, 0.6);

				@media @mobile {
					max-width: 100%;
				}

				.toggler-option {
					//border: 1px solid #bbc1cc;
					background-color: @White;
					color: #000;
					cursor: pointer;
					line-height: 40px;
					min-width: 80px;
					padding-left: 20px;
					padding-right: 20px;
					font-size: 14px;
					text-align: center;
					white-space: nowrap;

					&.disabled {
						cursor: initial;
						color: @Grey-1;
					}

					&:last-child:not(:only-child) {
						border-right: none;
					}
				}
			}
		}
	}
}

.action-box-trial {
	.box {
		height: 100%;
	}
	.actionbox-title {
		margin-bottom: 50px;
	}
	.actionbox-subtitle {
		color: @Grey-1;
	}
}

.footer-wrap {
	.cta-note .renewal-trigger,
	.subscription-trigger {
		cursor: pointer;

		.icon-info {
			display: inline-block;
			width: 18px;
			height: 18px;
			transition: all 200ms linear;
			position: relative;
			top: 3px;
			left: 3px;
			background: url('https://static2.avg.com/4/web/i/ico/ico-info-16x16-white.svg') no-repeat;
			background-size: 18px 18px;
		}
		&:hover {
			.icon-info {
				background: url('https://static2.avg.com/4/web/i/ico/ico-info-16x16-dark.svg') no-repeat;
				background-size: 18px 18px;
			}
		}
	}
	.nowrap {
		white-space: nowrap;
	}

	.yearly-price,
	.yearly-price-text,
	.bottom-text,
	.note_above_cta {
		color: @Grey-1;
		font-size: 12px;
		line-height: 18px;
	}

	.yearly-price {
		margin-bottom: 8px;

		s {
			color: @Grey-2;
		}
	}

	.yearly-price-text {
		margin-bottom: 4px;
	}
}

.en-gb .footer-wrap .cta-note .renewal-trigger {
	white-space: unset;
}


// long prices, period texts alignment

// default
.row-long {
	display: none;
}

// exceptions for all prices
[lang*='-id'] {
	.row-discount {
		.row-long {
			display: block;
		}
		.row-short {
			display: none;
		}
	}
}

[lang='ms-my'],
[lang='zh-tw'],
[lang='pl-pl'],
[lang='cs-cz'],
[lang='no-no'],
[lang='en-dk'],
[lang='en-se'],
[lang='en-in'],
[lang='en-ca'],
[lang='en-gb'] {
	.boxCount-3 {
		@media only screen and (min-width: 768px) and (max-width: 1345px) {
			.row-discount {
				.row-long {
					display: block;
				}
				.row-short {
					display: none;
				}
			}
		}
	}
}