@import "../../variables/variables.less";
@import 'vue-universal-actionbox.less';

@compTable-colName-width: 462px;
@compTable-colValue-width: 201px;


  #help-me-choose-tool {
  	width: 1023px;a
    min-height: 360px;
    margin: 0 auto 2.5rem auto;
    padding: 50px 0 60px;
    background-color: #f5f6fa;
    border-radius: 3px;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(187, 193, 204, 0.7);
    margin-top: -150px; 
    text-align: center;
    outline: none;

    &.expanded {
        padding-bottom: 0px;
    }

    .main-headline {
        margin-top: 0px;
        margin-bottom: 24px;
    }

    .h2sub {
        margin-bottom: 32px;
        font-size: 18px;
    }

    .hmct-categories {
        min-height: 127px;
        margin-top: 32px;
        outline: none;
        position: relative;
        top: 1px;
        &:active {
            outline: none;
        }
        &:focus {
            outline: none;
        }
        a {
            &:hover, &:focus, &:active {
                text-decoration: none;
            }            
        }
        .row {
            display: flex;
            flex-direction: row;
            padding: 0 0 1rem 0;
        }
        a {
            cursor: default;
        }
        .item {
            position: relative;
            width: 100%;
            padding: 39px 24px 35px;
            border-radius: 3px;
            box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
            background-color: #ffffff;            
            overflow: hidden;
            transition: all 200ms ease-in;    
            align-self: inherit;
            display: flex;
            flex-direction: column;
            height: 100%;
            &:not(.inactive) {
                bottom: -1rem;
            }

            h3 {
                margin: 0 0 auto 0;
                position: relative;
                font-size: 20px;
                font-family: 'AVGSans_Medium',Verdana,sans-serif;
                height: 100%;
                &.smaller {
                    font-size: 16px;    //used together with Latte cond if text is too long in some locales
                }
            }
            span.line {
                position: absolute;
                display: block;
                content: " ";
                height: 5px; 
                width: 100%;
                top: 0px;
                left: 0px;
                background-image: linear-gradient(91deg, #339922, #00b451);
            }
            &.protection span.line {
                background-image: linear-gradient(91deg, #339922, #00b451);
            }
            &.privacy span.line {
                background-image: linear-gradient(91deg, #e51603, #ff7b6f);
            }
            &.performance span.line {
                background-image: linear-gradient(to bottom, #249efc, #249efc), linear-gradient(91deg, #0066cc, #00aaff);
            }
            .button-wrapper {
                margin-top: 1rem;
                .button {
                    margin-bottom: 0;
                }
            }

            &.inactive {
                padding: 29px 24px 24px;   
                //margin-top: 10px;
                cursor: pointer;
                height: 100%;
                h3 {
                    font-family: 'AVGSans_Light',Verdana,sans-serif;
                    margin-bottom: 0;
                }
            }
            &:hover {
                //margin-top: 0px;
                margin-top: -10px;
                margin-bottom: 10px;
                //padding-top: 39px;
                &:not(.inactive) {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
    .hmct-content {
        position: relative;
        .hmct-content-item {
            display: none;
            background: #fff;
        }
        .hmct-level-controller {
            margin-top: 60px;
        }
        .hmct-slider-wrapper {
            padding: 40px 87px 50px;
            border-radius: 3px;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
            position: relative;
            &:before {
                display: block;
                width: 45px;
                height: 45px;
                content: " ";
                background: #fff;
                position: absolute;
                bottom: -20px;
                left: ~"calc(50% - 23px)";
                box-shadow: 14px 10px 16px 0px rgba(0, 0, 0, 0.05);
                transform: rotate(45deg);
            }
            //slider 
            .ui-slider {
                width: 624px;
                height: 4px;
                background: #d3d7dd;
                margin: 0px auto;
                border: none;
                .ui-slider-handle {
                    width: 44px;
                    height: 26px;
                    border-radius: 13.5px;        
                    top: -11px;
                    border: none;
                    margin-left: -23px;
                    cursor: pointer;
                    &:focus {
                        outline: none;
                    }
                    &:active {
                        border:none;
                    }
                }
                .ui-slider-range {
                    
                    cursor: pointer;
                }
                .tooltip {
                    display: block;
                    position: absolute;
                    padding: 0px 10px;
                    background: #d3d7dd;
                    border-radius: 2px;
                    font-size: 11px;
                    color: #000;
                    top: -50px;
                    left: 50%;
                    transform: translateX(-50%);
                    &:before {
                        display: block;
                        content: " ";
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        background: #d3d7dd;
                        bottom: -3px;
                        left: ~"calc(50% - 4px)"; 
                        transform: rotate(45deg);
                    }
                }
            }

            &.hmct-slider-wrapper-protection {
                .ui-slider {
                    .ui-slider-handle {
                        background: rgb(0, 180, 81) url('../i/other/arrows.svg') no-repeat center;
                    }
                    .ui-slider-range {
                        background-color: #7fd9a8;
                    }
                }
            }
            &.hmct-slider-wrapper-privacy {
                .ui-slider {
                    .ui-slider-handle {
                        background: #ea0029 url('../i/other/arrows.svg') no-repeat center;
                    }
                    .ui-slider-range {
                        background: #f8abb9;
                    }
                }
            }
            &.hmct-slider-wrapper-performance {
                .ui-slider {
                    .ui-slider-handle {
                        background: #2a7de1 url('../i/other/arrows.svg') no-repeat center;
                    }
                    .ui-slider-range {
                        background: #91cefd;
                    }
                }
            }

            .level-navigation {
                display: flex;
                justify-content: space-between;
                margin-top: 24px;
                a {
                    
                    text-decoration: none;
                    &:hover {
                        text-decoration: none;
                    }
                    .level {
                        width: 224px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        position: relative;
                        cursor: pointer;
                        &:before {
                            display: block;
                            content: " ";
                            width: 10px;
                            height: 10px;
                            position: absolute;
                            top: -31px;
                            left: ~"calc(50% - 5px)";
                            border-radius: 50%;
                        }
                        h4 {
                            font-size: 20px;
                            letter-spacing: -0.6px;
                        
                        }
                        .h4sub {
                            font-size: 16px;
                            max-width: 192px;
                            color: #5c707b;
                        }
                        span {
                            width: 30px;
                            height: 30px;
                            background: transparent;
                            position: absolute;
                            top: -40px;
                        }
                        &.active {
                            h4 {
                                font-family: 'AVGSans_Medium',Verdana,sans-serif;
                            }
                            .h4sub {
                                font-family: 'AVGSans_Medium',Verdana,sans-serif;
                                color: #000;
                            }
                        }
                        
                    }
                }
                &-protection {
                    .level-item {
                        &:before {
                            background: #7fd9a8
                        }
                    }                    
                }
                &-privacy {
                    .level-item {
                        &:before {
                            background: #f8abb9;
                        }
                    }
                }
                &-performance {
                    .level-item {
                        &:before {
                            background: #91cefd;
                        }
                    }
                }
                }
                
        }
        .hmct-content-wrapper {
            padding: 55px 60px 55px;

            @-webkit-keyframes fadeIn {
                from { opacity: 0; top: 50px; }
                  to { opacity: 1; top: 0px;}
            }
            @keyframes fadeIn {
                from { opacity: 0; top: 50px;}
                  to { opacity: 1; top: 0px;}
            }

            .hmct-product {
                display: none;
                transition: all 300ms ease-in;
                &.selected {
                    display: block;
                    -webkit-animation: fadeIn 0.3s;
                    animation: fadeIn 0.3s;
                }

                h3 {
                    font-size: 36px;
                    line-height: 1.17;
                    span {
                        font-family: 'AVGSans_Medium',Verdana,sans-serif;
                    }
                }

                .product-name {
                    margin: 16px 0 60px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .product-icon {
                        display: block;
                        margin-right: 24px;
                    }
                    h2 {
                        margin: 0px;
                        font-family: 'AVGSans_Medium',Verdana,sans-serif;
                    }
                }

                .features {
                    margin-top: 40px;
                    .item {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 24px;
                        h4 {
                            margin-top: 0px;
                            font-size: 20px;
                            font-family: 'AVGSans_Medium',Verdana,sans-serif;
                            letter-spacing: -0.6px;
                        }
                        p.description {
                            font-size: 14px;
                        }
                        .item-image {
                            min-width: 64px;
                          margin-right: 20px;
                        }
                    }
                }

                .plus-more {
                    font-size: 24px;
                }
                .list-tick {
                    list-style-type: none;
                    margin:40px 0 30px;
                    padding: 0px;
                    li {
                        padding-left: 32px;
                        margin-bottom: 10px;
                        span {
                            color: #000;
                            font-weight: bold;
                        }
                    }
                    &.list-tick-green {
                        li {
                            background: url('../i/other/tick-green.svg') no-repeat left top 3px;
                        }
                        
                    }
                }

                &.hmct-product-performance-advanced {
                    .list-tick {
                        margin-top: 20px!important;
                    }
                }
                
                .more-features {
                    font-size: 14px;
                    margin-left: 33px;
                }

                &.hmct-product-performance-complete {
                    .more-features {
                        margin-left: 0px!important;
                    }
                }
                &.hmct-product-privacy-complete {
                    .more-features {
                        margin-left: 0px!important;
                    }
                }
                &.hmct-product-protection-complete {
                    .more-features {
                        margin-left: 0px!important;
                    }
                }
                
                .comparison-table {
                    margin: 47px 29px 40px;
                    .product-feature-name {
                        min-width: @compTable-colName-width;
                        min-height: 1px;
                    }
                    .table-header {
                        display: flex;
                        flex-direction: row;     
                        margin-bottom: 30px;                  

                        .product-col {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: @compTable-colValue-width;
                            //padding: 0 20px;
                            .product-icon {
                                margin-bottom: 10px;
                            }
                            .product-name-ct {
                                font-size: 20px;
                                color: #000;
                                font-family: 'AVGSans_Book', Verdana, sans-serif;
                                text-align: center;
                                margin-bottom: 10px;
                                letter-spacing: -0.6px;
                                height: 100%;

                            }
                            .product-features-link {
                                margin-top: 20px;
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    .table-content {
                        margin-bottom: 56px;
                        .table-row {
                            display: flex;
                            flex-direction: row;
                            border-bottom: 1px solid #d3d7dd;
                            &:last-of-type {
                                border: none;
                            }
                        }
                        .product-col-name {
                            min-width: @compTable-colName-width;
                            font-size: 14px;
                        }
                        .product-col-name,.product-col {
                            min-height: 58px;
                            padding: 17px 0;
                        }
                        .product-col {
                            width: @compTable-colValue-width;
                            padding: 0 20px;
                            display: flex;
                            justify-content: center;
                            img {
                                align-self: center;
                            }
                        }
                        .bg-gray {
                            background: #f5f6fa;
                        }
                    }
                    .table-footer {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;

                        .product-col-name {
                            width: @compTable-colName-width;
                        }
                        .product-col {
                            width: @compTable-colValue-width;
                            .btn-medium {
                                height: 70px;
                                display: flex !important;
                                align-items: center;
                            }
                            &.right {
                                .btn-medium {
                                    margin: 0 20px;
                                }
                            }
                            span {
                                font-size: 13px;
                                display: block;
                                letter-spacing: -0.44px;
                                color: #5c707b;
                                margin-top: 8px;
                            }
                        }
                    }
                }
                .included-products {
                    margin-top: 40px;
                    .item {
                        margin-bottom: 30px;
                        display: flex;
                        flex-direction: row;
                        .included-product-icon {
                            margin-right: 20px;
                        }
                        .included-product-content {
                            h4 {
                                font-family: 'AVGSans_Medium',Verdana,sans-serif;
                                font-size: 20px;
                                margin-bottom: 10px;
                                margin-top: 2px;
                            }
                            .list-tick {
                                margin: 0px;
                                li {
                                    font-size: 14px;
                                    &:last-of-type {
                                        margin-bottom: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
                //actionbox adjustments 
                .vue-action-box {
                    height: auto;
                }
                .col-md-5 {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                }
                .actionbox {
                    &.has-toggler {
                        margin-top: 32px;
                    }
                    width: 340px;

                    .money-back-guarantee {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        margin-top: 30px;
                        img {
                            margin-right: 10px;
                        }
                        span {
                            font-size: 14px;
                            letter-spacing: -0.42px;
                            color: #5d707b;
                        }
                    }
                }
                .actionbox-container {
                    width: 340px;
                    border: 1px solid #dfe4ec;
                    border-radius: 3px;
                    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
                    .vue-action-box .bottom-text {
                        position: static;
                    }
                }
                .vue-action-box .bottom-text {
                    position: static;
                }
            }
        }
    }

    //buttons 
    .btn {
        display: inline-block;
        &:hover {
            text-decoration: none;
        }
    }

    .btn-square {
        border-radius: 4px;
    }

    .btn-medium {
        padding: 12px 23px 12px 50px;
        font-size: 16px;
        letter-spacing: -0.39px;
    }

    .btn-blue {
        color: #2a7de1;
        border: solid 1px #2a7de1;
    }

    .btn-win {
        background: url('../i/platform-icons/hmct_tool/win-blue.svg') no-repeat 26px center; 
    }
    .btn-mac {
        background: url('../i/platform-icons/hmct_tool/mac-blue.svg') no-repeat 26px center; 
    }

    //helpers
    .no-margin {
        margin: 0px;
    }

    .mt60 {
        margin-top: 60px;
    }  

    .js-android & {
        display: none!important;
    }
    .js-ios & {
        display: none!important;
    }
}



@media only screen and (max-width: 1024px) {
    #help-me-choose-tool {
        display: none;
    }
}

.actionbox-free {
    text-align: center;
    margin-top: 17px;
    .actionbox-container {
        padding: 30px;
    }
    .headline {
        margin-bottom: 16px;
        span {
            font-family: 'AVGSans_Light',Verdana,sans-serif;
            font-size: 16px;
            color: #000000;
        }
    }
    .icons {
        margin-bottom: 24px;
        img {
            margin: 0 4px;
        }
    }
    .price {
        margin-bottom: 31px;
        margin-top: 15px;
        span {
            font-size: 34px;
            font-family: 'AVGSans_Light',Verdana,sans-serif;
            color: #1e222a;
            letter-spacing: -0.62px;
        }
    }
    .price-additional-message {
        margin-bottom: 32px;
        span {
            font-size: 13px;
            letter-spacing: -0.44px;
            color: #5c707b;
            font-family: 'AVGSans_Book',Verdana,sans-serif;
        }
    }
    .btn {
        margin-bottom: 8px;
    }
    .button-additional-message {
        span {
            font-size: 13px;
            font-family: 'AVGSans_Book',Verdana,sans-serif;
            letter-spacing: -0.44px;
            color: #5c707b;
        }
    }
}

.js-ios {
    .banner-0 {
        min-height: 500px!important;
        padding-top: 140px!important;
        background-repeat: no-repeat!important;
        background-position: center top!important;
        background-color: #1a0a0b!important;
      }
    
      @media (max-width: 767px) {
        .banner-0 {
          padding-top: 70px!important;
        }
      }
    
      @media (max-width: 479px) {
        .banner-0 {
          background-repeat: no-repeat!important;
          background-position: center center!important;
        }
      }
}
.js-android {
    .banner-0 {
        min-height: 500px!important;
        padding-top: 140px!important;
        background-repeat: no-repeat!important;
        background-position: center top!important;
        background-color: #1a0a0b!important;
      }
    
      @media (max-width: 767px) {
        .banner-0 {
          padding-top: 70px!important;
        }
      }
    
      @media (max-width: 479px) {
        .banner-0 {
          background-repeat: no-repeat!important;
          background-position: center center!important;
        }
      }
}

.en-ww, .en-us, .en-eu, .en-gb, .en-ca, .en-au, .en-nz, .en-in, .en-za, .en-se, .en-dk { 
    .comparison-table {
        .table-footer {
            .product-col {
                .btn-medium {
                    height: auto !important;
                }
            }
        }
    }
}